import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import axios from 'axios';
import io from 'socket.io-client';
import Confetti from 'react-confetti';
import './Multiplayer.css';
import 'leaflet/dist/leaflet.css';
import geojsonData from './countries.json';
import '../Game.css';

// Use environment variable with fallback for development
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://mighty-headland-49509-56ec7d863bb1.herokuapp.com';

// Initialize socket outside component to prevent multiple connections
let socket;

const initializeSocket = (nickname) => {
  if (socket) {
    console.log('Socket exists, cleaning up...');
    socket.close();
  }

  console.log('Initializing socket connection to:', BACKEND_URL);
  
  socket = io(BACKEND_URL, {
    transports: ['websocket', 'polling'],
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    timeout: 10000,
    forceNew: true,
    query: { nickname }
  });

  return socket;
};

const Multiplayer = () => {
  const [audioUrl, setAudioUrl] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);
  const [round, setRound] = useState(1);
  const [score, setScore] = useState(0);
  const [opponentScore, setOpponentScore] = useState(0);
  const [hasAnswered, setHasAnswered] = useState(false);
  const [timer, setTimer] = useState(30);
  const [disableListenAgain, setDisableListenAgain] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryStyles, setCountryStyles] = useState({});
  const [blink, setBlink] = useState(false);
  const [waitingForOpponent, setWaitingForOpponent] = useState(true);
  const [gameEnded, setGameEnded] = useState(false);
  const [endGameMessage, setEndGameMessage] = useState('');
  const [roundEndMessage, setRoundEndMessage] = useState('');
  const [gameId, setGameId] = useState(null);
  const [showRematchButton, setShowRematchButton] = useState(false);
  const [rematchRequested, setRematchRequested] = useState(false);
  const [rematchAccepted, setRematchAccepted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [nextRoundCountdown, setNextRoundCountdown] = useState(null);
  const [consecutiveCorrect, setConsecutiveCorrect] = useState(0);
  const consecutiveCorrectRef = useRef(0);
  const [isAudioReady, setIsAudioReady] = useState(false);
  const [isTransitioningRound, setIsTransitioningRound] = useState(false);
  
  const currentCountryRef = useRef('');
  const timerIdRef = useRef(null);
  const audioRef = useRef(null);
  const socketRef = useRef(null);
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');
  const [opponentNickname, setOpponentNickname] = useState('');
  const [onlinePlayers, setOnlinePlayers] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState('connecting');
  const [errorMessage, setErrorMessage] = useState('');

  const defaultCountryStyle = {
    fillColor: '#3388ff',
    weight: 1,
    color: 'black',
    fillOpacity: 0.4,
  };

  const updateCountryStyle = (countryName, newStyle) => {
    setCountryStyles(prevStyles => ({
      ...prevStyles,
      [countryName]: newStyle,
    }));
  };

  const onEachCountry = (feature, layer) => {
    const countryName = feature.properties.ADMIN;
    
    // Create a tooltip instance for this layer
    const tooltip = layer.bindTooltip(countryName, {
      permanent: false,
      sticky: true,
      direction: 'auto',
      className: 'country-label'
    });
    
    layer.on({
      click: () => {
        handleCountryClick(countryName);
      },
      mouseover: () => {
        if (!hasAnswered && !gameEnded) {
          layer.setStyle({
            fillColor: '#3388ff',
            weight: 2,
            color: 'black',
            fillOpacity: 0.4,
          });
          if (layer.isTooltipOpen()) {
            layer.closeTooltip();
          }
          layer.openTooltip();
        }
      },
      mouseout: () => {
        if (!hasAnswered && !gameEnded) {
          layer.setStyle(getCountryStyle(feature));
          if (layer.isTooltipOpen()) {
            layer.closeTooltip();
          }
        }
      },
    });
  };

  const handleCountryClick = (countryName) => {
    if (!countryName || gameEnded || hasAnswered || timer === 0) return;
    
    if (!currentCountryRef.current) {
      console.error('No current country set');
      return;
    }

    try {
      const clickedCountryNormalized = countryName.trim().toLowerCase();
      const expectedCountryNormalized = currentCountryRef.current.trim().toLowerCase();
      const result = clickedCountryNormalized === expectedCountryNormalized;

      setIsCorrect(result);
      setHasAnswered(true);

      const newStyle = {
        fillColor: result ? '#4CAF50' : '#f44336',
        weight: 2,
        color: 'black',
        fillOpacity: 0.7,
      };
      updateCountryStyle(countryName, newStyle);

      let totalScore = 0;
      if (result) {
        // Base score calculation
        let baseScore = 100;
        // Time bonus (up to 100 points based on remaining time)
        let timeBonus = Math.round((timer / 30) * 100);
        totalScore = baseScore + timeBonus;

        // Streak bonus (10% extra per consecutive correct answer)
        if (consecutiveCorrectRef.current >= 1) {
          const streakBonus = totalScore * 0.1 * consecutiveCorrectRef.current;
          totalScore += streakBonus;
        }

        const finalScore = Math.round(totalScore);
        setScore(prevScore => prevScore + finalScore);
        
        // Update streak
        setConsecutiveCorrect(prev => {
          const newStreak = prev + 1;
          consecutiveCorrectRef.current = newStreak;
          return newStreak;
        });
        
        setShowConfetti(true);
        
        // Notify server of score update with the exact final score
        socketRef.current.emit('playerAnswer', { score: score + finalScore });
      } else {
        // Reset streak on wrong answer
        setConsecutiveCorrect(0);
        consecutiveCorrectRef.current = 0;
      }

      // Show the correct country after a delay if answer was wrong
      if (!result) {
        setTimeout(() => {
          updateCountryStyle(currentCountryRef.current, {
            fillColor: '#4CAF50',
            weight: 2,
            color: 'black',
            fillOpacity: 0.7,
          });
        }, 500);
      }

      // Notify server of answer with the exact score
      socketRef.current.emit('roundAnswer', {
        correct: result,
        score: result ? score + Math.round(totalScore) : score
      });
    } catch (error) {
      console.error('Error in handleCountryClick:', error);
    }
  };

  const getCountryStyle = (feature) => {
    return countryStyles[feature.properties.ADMIN] || defaultCountryStyle;
  };

  const handleReplayAudio = useCallback(() => {
    if (!audioRef.current || isTransitioningRound) return;
    
    try {
      audioRef.current.currentTime = 0;
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Audio replay failed:', error);
          setErrorMessage('Failed to replay audio. Please try again.');
        });
      }
    } catch (error) {
      console.error('Error in audio replay:', error);
      setErrorMessage('An error occurred while replaying audio.');
    }
  }, [isTransitioningRound]);

  useEffect(() => {
    console.log('Setting up socket connection...');
    const newSocket = initializeSocket(localStorage.getItem('nickname'));
    socketRef.current = newSocket;

    // Socket event handlers
    newSocket.on('connect', () => {
      console.log('Connected to server');
      setConnectionStatus('connected');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      setConnectionStatus('error');
      setErrorMessage('Failed to connect to game server. Please try again.');
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
      setConnectionStatus('disconnected');
      setErrorMessage('Lost connection to game server. Attempting to reconnect...');
    });

    newSocket.on('opponentDisconnected', () => {
      setErrorMessage('Your opponent has disconnected. Game will end shortly.');
      if (audioRef.current) {
        audioRef.current.pause();
      }
    });

    newSocket.on('roundStart', ({ newRound, audioURL, country }) => {
      console.log('Round start:', { newRound, audioURL, country });
      setIsTransitioningRound(true);
      
      // Reset game state for new round
      setHasAnswered(false);
      setIsCorrect(null);
      setTimer(30);
      setCountryStyles({});
      currentCountryRef.current = country;
      
      // Update round number
      setRound(newRound);
      
      // Handle audio - audioURL is relative path from public folder
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      
      // Use the relative path directly - it will be served from public folder
      setAudioUrl(audioURL);
      
      // Allow audio to play after state updates
      setTimeout(() => {
        setIsTransitioningRound(false);
      }, 200);
    });

    newSocket.on('roundEnd', ({ correctCountry, scores }) => {
      console.log('Round end:', { correctCountry, scores });
      setIsTransitioningRound(true);
      
      if (correctCountry) {
        updateCountryStyle(correctCountry, {
          fillColor: '#4CAF50',
          weight: 2,
          color: 'black',
          fillOpacity: 0.7,
        });
      }

      // Update scores
      if (scores && scores.length > 0) {
        scores.forEach(([id, playerScore]) => {
          if (id === newSocket.id) {
            setScore(playerScore);
          } else {
            setOpponentScore(playerScore);
          }
        });
      }

      // Pause audio
      if (audioRef.current) {
        audioRef.current.pause();
      }
      
      setTimeout(() => {
        setIsTransitioningRound(false);
      }, 1500);
    });

    // Cleanup function
    return () => {
      console.log('Cleaning up socket connection...');
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, []);

  useEffect(() => {
    console.log('Setting up socket connection...');
    const newSocket = initializeSocket(localStorage.getItem('nickname'));
    socketRef.current = newSocket;
    
    newSocket.on('connect', () => {
      console.log('Connected to server');
      setConnectionStatus('connected');
      newSocket.emit('joinGame', { nickname: localStorage.getItem('nickname') });
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      setConnectionStatus('error');
    });

    newSocket.on('waitingForOpponent', () => {
      console.log('Waiting for opponent...');
      setWaitingForOpponent(true);
    });

    newSocket.on('playerCount', (count) => {
      console.log('Online players:', count);
      setOnlinePlayers(count);
    });

    newSocket.on('matchFound', ({ gameId, players }) => {
      console.log('Match found:', players);
      setGameId(gameId);
      setWaitingForOpponent(false);
      const opponent = players.find(p => p.id !== socketRef.current.id);
      setOpponentNickname(opponent.nickname);
    });

    newSocket.on('roundStart', ({ newRound, audioURL, country }) => {
      console.log('Round start:', { newRound, audioURL, country });
      setIsTransitioningRound(true);
      
      // Reset game state for new round
      setHasAnswered(false);
      setIsCorrect(null);
      setTimer(30);
      setCountryStyles({});
      currentCountryRef.current = country;
      
      // Update round number and audio URL
      setRound(newRound);
      setAudioUrl(audioURL);
      
      // Allow audio to play after state updates
      setTimeout(() => {
        setIsTransitioningRound(false);
      }, 200);
    });

    newSocket.on('roundEnd', (data) => {
      console.log('Round end:', data);
      setIsTransitioningRound(true);
      
      if (data.correctCountry) {
        // Show correct answer
        updateCountryStyle(data.correctCountry, {
          fillColor: '#4CAF50',
          weight: 2,
          color: 'black',
          fillOpacity: 0.7,
        });
      }

      // Update scores if provided
      if (data.scores && data.scores.length > 0) {
        data.scores.forEach(playerScore => {
          if (playerScore.nickname === localStorage.getItem('nickname')) {
            setScore(playerScore.score);
          } else {
            setOpponentScore(playerScore.score);
          }
        });
      }
      
      // Reset for next round after a delay
      setTimeout(() => {
        setIsTransitioningRound(false);
      }, 1500);
    });

    newSocket.on('gameState', (state) => {
      console.log('Received game state:', state);
    });

    newSocket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    newSocket.on('playerAnswered', ({ playerId, score: newScore }) => {
      if (playerId !== socketRef.current.id) {
        setOpponentScore(newScore);
      }
    });

    newSocket.on('gameEnd', ({ finalScores }) => {
      const playerScore = finalScores.find(([id]) => id === socketRef.current.id)?.[1] || 0;
      const oppScore = finalScores.find(([id]) => id !== socketRef.current.id)?.[1] || 0;
      setScore(playerScore);
      setOpponentScore(oppScore);
      
      const playerWon = playerScore > oppScore;
      const isTie = playerScore === oppScore;
      
      setGameEnded(true);
      setEndGameMessage(
        isTie 
          ? "It's a tie!" 
          : playerWon 
            ? '🏆 Congratulations! You won!' 
            : 'Game Over! Better luck next time!'
      );
      
      // Show confetti for winners and ties
      if (playerWon || isTie) {
        setShowConfetti(true);
      }
    });

    newSocket.on('opponentLeft', () => {
      console.log('Opponent left the game');
      setGameEnded(true);
      setEndGameMessage('Your opponent left the game. You win!');
    });

    newSocket.on('rematchRequested', () => {
      setEndGameMessage('Opponent wants a rematch! Accept?');
      setShowRematchButton(true);
    });

    newSocket.on('rematchAccepted', () => {
      setGameEnded(false);
      setRound(1);
      setScore(0);
      setOpponentScore(0);
      setShowRematchButton(false);
      setRematchRequested(false);
      setRematchAccepted(false);
      setEndGameMessage('');
      setShowConfetti(false);
      setCountryStyles({});
    });

    newSocket.connect();

    return () => {
      if (newSocket) {
        console.log('Cleaning up socket connection...');
        newSocket.close();
      }
    };
  }, [navigate]);

  useEffect(() => {
    if (gameEnded || !audioUrl || !audioRef.current || isTransitioningRound) return;
    
    const playAudio = () => {
      try {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.src = audioUrl;
        audioRef.current.load();
        
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.error('Audio playback failed:', error);
            setErrorMessage('Failed to play audio. Please try again.');
          });
        }
      } catch (error) {
        console.error('Error in audio playback:', error);
        setErrorMessage('An error occurred while playing audio.');
      }
    };

    // Small delay to ensure audio element is ready
    const timeoutId = setTimeout(playAudio, 100);
    
    return () => {
      clearTimeout(timeoutId);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, [audioUrl, gameEnded, isTransitioningRound]);

  useEffect(() => {
    if (waitingForOpponent || gameEnded) return;

    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0 || hasAnswered) {
          clearInterval(timerInterval);
          if (prevTimer <= 0 && !hasAnswered) {
            setIsCorrect(false);
            setHasAnswered(true);
            // Show correct country when time runs out
            if (currentCountryRef.current) {
              updateCountryStyle(currentCountryRef.current, {
                fillColor: '#4CAF50',
                weight: 2,
                color: 'black',
                fillOpacity: 0.7,
              });
            }
          }
          return 0;
        }
        if (prevTimer <= 5) {
          setBlink(true);
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [waitingForOpponent, gameEnded, hasAnswered]);

  // Reset blink when timer changes
  useEffect(() => {
    if (timer > 5) {
      setBlink(false);
    }
  }, [timer]);

  const endGame = () => {
    setGameEnded(true);
    const playerWon = score > opponentScore;
    const isTie = score === opponentScore;
    setEndGameMessage(
      isTie 
        ? "It's a tie!" 
        : playerWon 
          ? '🏆 Congratulations! You won!' 
          : 'Game Over! Better luck next time!'
    );
    setShowConfetti(true);
  };

  const handleRematchRequest = () => {
    socketRef.current.emit('rematchRequest');
    setRematchRequested(true);
    setEndGameMessage('Waiting for opponent to accept rematch...');
  };

  const handleRematchAccept = () => {
    socketRef.current.emit('rematchAccept');
    setRematchAccepted(true);
  };

  const AudioControls = () => (
    <div className="flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4">
      <audio
        className="w-full sm:w-2/3 md:w-1/2"
        ref={audioRef}
        src={audioUrl}
        controls
        onError={(e) => console.error('Error with audio playback:', e)}
      />
      <button
        onClick={handleReplayAudio}
        className="w-full sm:w-auto px-6 py-2 bg-black/30 hover:bg-black/40 text-white rounded transition-colors border border-white/20"
        disabled={isTransitioningRound}
      >
        Listen Again
      </button>
    </div>
  );

  const GameEndOverlay = () => {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
          <div className="text-center space-y-4">
            <h2 className="text-3xl font-bold text-white mb-4">{endGameMessage}</h2>
            
            <div className="bg-white/10 rounded-lg p-4 mb-4">
              <div className="grid grid-cols-2 gap-4 text-white">
                <div>
                  <p className="text-lg opacity-80">Your Score</p>
                  <p className="text-3xl font-bold">{score}</p>
                </div>
                <div>
                  <p className="text-lg opacity-80">Opponent's Score</p>
                  <p className="text-3xl font-bold">{opponentScore}</p>
                </div>
              </div>
            </div>
            
            <div className="flex justify-between items-center space-x-4">
              {showRematchButton ? (
                <div className="flex-1 flex space-x-2">
                  {!rematchRequested ? (
                    <button
                      onClick={handleRematchRequest}
                      className="flex-1 px-4 py-2 bg-white/20 hover:bg-white/30 text-white rounded transition-colors"
                    >
                      Request Rematch
                    </button>
                  ) : rematchAccepted ? (
                    <div className="flex-1 text-white">Starting new game...</div>
                  ) : (
                    <button
                      onClick={handleRematchAccept}
                      className="flex-1 px-4 py-2 bg-white/20 hover:bg-white/30 text-white rounded transition-colors"
                    >
                      Accept Rematch
                    </button>
                  )}
                </div>
              ) : null}
              <button
                onClick={() => navigate('/')}
                className="flex-1 px-4 py-2 bg-white/20 hover:bg-white/30 text-white rounded transition-colors"
              >
                Back to Menu
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (waitingForOpponent) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white p-4">
        <h1 className="text-4xl font-bold text-white mb-8">LangGuesser</h1>
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500 mb-4"></div>
        <h2 className="text-2xl font-bold mb-2">Waiting for opponent...</h2>
        <p className="text-gray-400 mb-2">
          {connectionStatus === 'connected' 
            ? 'Stay tuned while we find a match for you!'
            : connectionStatus === 'error'
            ? 'Connection error. Trying to reconnect...'
            : connectionStatus === 'disconnected'
            ? 'Disconnected from server. Reconnecting...'
            : connectionStatus === 'connecting'
            ? 'Connecting to server...'
            : 'Connecting to server...'}
        </p>
        <p className="text-sm text-blue-400">
          Players online right now: {onlinePlayers}
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          colors={['#FFD700', '#FFA500', '#FF4500', '#9370DB', '#4169E1']}
        />
      )}
      {gameEnded ? (
        <GameEndOverlay />
      ) : (
        <div className="container mx-auto">
          <div className="flex flex-col items-center justify-center space-y-4">
            {/* Game Header */}
            <div className="w-full flex justify-between items-center mb-4">
              <div className="text-xl">
                Round: {round}/10
              </div>
              <div className="text-xl">
                Time: {timer}s
              </div>
            </div>

            {/* Scores */}
            <div className="w-full flex justify-between items-center mb-4">
              <div className="text-lg">
                Your Score: {score}
              </div>
              <div className="text-lg">
                Opponent's Score: {opponentScore}
              </div>
            </div>

            {/* Audio Controls */}
            <AudioControls />

            {/* Map Container */}
            <div className="w-full h-[60vh] relative">
              <MapContainer
                center={[20, 0]}
                zoom={2}
                style={{ height: "100%", width: "100%" }}
                minZoom={2}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <GeoJSON
                  data={geojsonData}
                  style={getCountryStyle}
                  onEachFeature={onEachCountry}
                />
              </MapContainer>
            </div>

            {/* Status Messages */}
            {errorMessage && (
              <div className="text-red-500 mt-4">{errorMessage}</div>
            )}
            {waitingForOpponent && (
              <div className="text-yellow-500 mt-4 blink-class">
                Waiting for opponent...
              </div>
            )}
            {isCorrect !== null && (
              <div className={`mt-4 ${isCorrect ? 'text-green-500' : 'text-red-500'}`}>
                {isCorrect ? 'Correct!' : 'Wrong!'}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Multiplayer;
