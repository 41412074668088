import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Modal } from 'antd';

const NicknameInput = () => {
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');
  const [isError, setIsError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMaintenanceModalVisible, setIsMaintenanceModalVisible] = useState(false);
  const [shake, setShake] = useState(false);

  useEffect(() => {
    const skipNicknameInput = localStorage.getItem('skipNicknameInput');
    const existingNickname = localStorage.getItem('nickname');
    
    if (skipNicknameInput === 'true' && existingNickname) {
      localStorage.removeItem('skipNicknameInput');
      navigate('/game');
    }
  }, [navigate]);

  useEffect(() => {
    if (shake) {
      const timer = setTimeout(() => setShake(false), 500);
      return () => clearTimeout(timer);
    }
  }, [shake]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nickname.trim()) {
      setIsError(true);
      setShake(true);
      return;
    }
    setIsError(false);
    setIsModalVisible(true);
  };

  const handleGameModeSelect = (mode) => {
    if (!nickname.trim()) {
      setIsError(true);
      setShake(true);
      return;
    }
    setIsError(false);
    localStorage.setItem('nickname', nickname);
    localStorage.setItem('gameMode', mode);
    setIsModalVisible(false);
    
    if (mode === 'multiplayer') {
      setIsMaintenanceModalVisible(true);
    } else {
      navigate('/game');
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      <style>
        {`
          @keyframes shake {
            0%, 100% { transform: translateX(0); }
            10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
            20%, 40%, 60%, 80% { transform: translateX(5px); }
          }
          .shake {
            animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
          }
        `}
      </style>
      <form onSubmit={handleSubmit} className="flex flex-col items-center w-full">
        <div className={`w-full relative mb-4 ${shake ? 'shake' : ''}`}>
          <input
            type="text"
            className={`px-4 py-2 rounded shadow-lg text-black w-full text-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
              isError ? 'border-2 border-red-500' : ''
            }`}
            placeholder="Enter your nickname"
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value);
              setIsError(false);
            }}
          />
        </div>
        <button type="submit" className="p-[3px] relative mb-4"> 
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg" />
          <div className="px-8 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent"> 
            Play Now
          </div> 
        </button>
      </form>

      <div className="flex items-center justify-center space-x-4 w-full">
        <button 
          onClick={() => handleGameModeSelect('solo')}
          className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
        >
          Solo Play
        </button>
        <span className="text-white font-light">|</span>
        <button 
          onClick={() => handleGameModeSelect('multiplayer')}
          className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
        >
          Multiplayer
        </button>
      </div>

      <Modal
        title={
          <div className="text-center font-bold text-lg">
            Choose Game Mode
          </div>
        }
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        className="game-mode-modal"
      >
        <div className="flex flex-col space-y-4 p-4">
          <button
            onClick={() => handleGameModeSelect('solo')}
            className="w-full p-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105"
          >
            Solo Play
          </button>
          <button
            onClick={() => handleGameModeSelect('multiplayer')}
            className="w-full p-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105"
          >
            1v1 Multiplayer
          </button>
        </div>
      </Modal>

      {/* Maintenance Modal */}
      <Modal
        title={
          <div className="text-center font-bold text-lg text-yellow-500">
            🛠️ Under Maintenance
          </div>
        }
        open={isMaintenanceModalVisible}
        onCancel={() => setIsMaintenanceModalVisible(false)}
        footer={[
          <button
            key="back"
            onClick={() => setIsMaintenanceModalVisible(false)}
            className="w-full p-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded hover:from-purple-600 hover:to-pink-600 transition-all duration-300"
          >
            Back to Menu
          </button>
        ]}
        centered
        className="maintenance-modal"
      >
        <div className="text-center p-4">
          <p className="text-lg mb-4">
            The multiplayer mode is currently under maintenance. Please try again later!
          </p>
          <p className="text-gray-500">
            In the meantime, you can enjoy our Solo Play mode to test your language guessing skills!
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default NicknameInput;
