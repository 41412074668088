import React from 'react';
import { Link } from 'react-router-dom';

const languageDatabase = [
  { language: "Albanian", countries: ["Albania"] },
  { language: "Arabic", countries: ["Morocco"] },
  { language: "Armenian", countries: ["Armenia"] },
  { language: "Azerbaijani", countries: ["Azerbaijan"] },
  { language: "Belarussian", countries: ["Belarus"] },
  { language: "Berber", countries: ["Morocco"] },
  { language: "Bulgarian", countries: ["Bulgaria"] },
  { language: "Chinese", countries: ["China"] },
  { language: "Czech", countries: ["Czechia"] },
  { language: "Danish", countries: ["Denmark"] },
  { language: "Dutch", countries: ["Netherlands"] },
  { language: "English", countries: ["Australia", "Canada", "South Africa", "Scotland", "England", "Ireland", "New Zealand", "USA"] },
  { language: "Estonian", countries: ["Estonia"] },
  { language: "Finnish", countries: ["Finland"] },
  { language: "French", countries: ["France", "Canada"] },
  { language: "Georgian", countries: ["Georgia"] },
  { language: "German", countries: ["Austria", "Germany"] },
  { language: "Greek", countries: ["Greece"] },
  { language: "Hindi", countries: ["India"] },
  { language: "Hokkien", countries: ["Taiwan"] },
  { language: "Hungarian", countries: ["Hungary"] },
  { language: "Indonesian", countries: ["Indonesia"] },
  { language: "Italian", countries: ["Italy"] },
  { language: "Japanese", countries: ["Japan"] },
  { language: "Kazakh", countries: ["Kazakhstan"] },
  { language: "Korean", countries: ["South Korea"] },
  { language: "Kyrgyz", countries: ["Kyrgyzstan"] },
  { language: "Latvian", countries: ["Latvia"] },
  { language: "Lithuanian", countries: ["Lithuania"] },
  { language: "Malay", countries: ["Malaysia"] },
  { language: "Norwegian", countries: ["Norway"] },
  { language: "Pashto", countries: ["Afghanistan"] },
  { language: "Persian", countries: ["Iran"] },
  { language: "Polish", countries: ["Poland"] },
  { language: "Portuguese", countries: ["Brazil", "Portugal", "Angola"] },
  { language: "Romanian", countries: ["Romania"] },
  { language: "Russian", countries: ["Russia"] },
  { language: "Slovakian", countries: ["Slovakia"] },
  { language: "Spanish", countries: ["Spain", "Argentina", "Colombia", "Mexico", "Peru", "Costa Rica", "Cuba", "Guatemala", "Panama"] },
  { language: "Swedish", countries: ["Sweden"] },
  { language: "Thai", countries: ["Thailand"] },
  { language: "Turkish", countries: ["Turkey"] },
  { language: "Turkmen", countries: ["Turkmenistan"] },
  { language: "Ukrainian", countries: ["Ukraine"] },
  { language: "Urdu", countries: ["Pakistan"] },
  { language: "Uzbek", countries: ["Uzbekistan"] },
  { language: "Vietnamese", countries: ["Vietnam"] },
];

const Languages = () => {
  return (
    <div
      className="min-h-screen flex flex-col items-center p-4 "
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Header Section */}
      <div className="text-center mb-5 items-center p-4 bg-gradient-to-br from-purple-700 via-blue-600 to-teal-500 text-white rounded-xl">
        <img
          className="mt-1 rounded-full mx-auto mb-1 shadow-md border-6 border-blue-700 border-opacity-30"
          src="/images/logo3.png"
          alt="LangGuesser"
          width={150}
          height={150}
        />
        <h1 className="text-4xl font-bold mb-1">LangGuesser</h1>
        <p className="text-md italic mb-4">Guess the language's country by its accent</p>
        <div className="text-center mb-1 items-center p-4 bg-gradient-to-br from-purple-800 via-blue-700 to-teal-700 text-white rounded-xl">
        <h2 className="text-3xl font-bold mb-1">Language Database</h2>
        <p className="text-lg">Total audio clips: 88</p>
        <p className="italic">As of v0.2</p>
        </div>
      </div>

      {/* Language List */}
      <div
        className="w-full max-w-3xl bg-white bg-opacity-10 rounded-lg p-6 shadow-lg overflow-y-auto bg-gradient-to-br from-teal-800 via-purple-700 to-blue-400"
        style={{
          maxHeight: '60vh',
          backdropFilter: 'blur(10px)',
        }}
      >
        {languageDatabase.map((entry, index) => (
          <div key={index} className="mb-2">
            <span className="font-bold">{entry.language}</span> - {entry.countries.join(", ")}
            {entry.regions && (
              <div className="ml-4">
                {Object.keys(entry.regions).map((region, index) => (
                  <div key={index}>
                    <span className="font-bold">{region}</span> - {entry.regions[region].join(", ")}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Footer Section */}
      <div className="mt-6 text-center">
        <Link to="/" className="font-bold text-green-300 hover:text-blue-100">
          Go back
        </Link>
      </div>
    </div>
  );
};

export default Languages;
