import React, { useEffect, useRef } from 'react';

const Instructions = ({ isOpen, onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isOpen && modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-10 flex justify-center items-center p-4">
      <div
        className="rounded-lg shadow-lg bg-gradient-to-br from-purple-700 via-blue-600 to-teal-500 text-white w-full max-w-3xl mx-auto opacity-95"
        style={{
          backdropFilter: 'blur(10px)',
          maxHeight: '90vh',
        }}
        ref={modalRef}
      >
        <button
          className="absolute top-2 right-2 text-xl font-bold text-white"
          onClick={onClose}
        >
          &times;
        </button>
        <div
          className="overflow-y-auto p-6"
          style={{
            maxHeight: '85vh',
          }}
        >
          <h2 className="text-3xl font-bold mb-6 text-center">How to Play</h2>

          <p className="mb-4">
            Listen to a 15-second audio clip and guess which country the language or accent is from.
          </p>

          <ul className="list-disc list-inside space-y-2 ml-4">
            <li>Total Rounds: 10. Each round presents a new audio clip.</li>
            <li>Elimination after 3 wrong answers.</li>
            <li>Score points based on speed and accuracy. Consecutive correct answers increase your score multiplier.</li>
            <li>The leaderboard displays the top 10 worldwide players based on scores.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
