import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Game from './components/Game';
import Multiplayer from './components/Multiplayer';
import Leaderboard from './components/Leaderboard';
import NicknameInput from './components/NicknameInput';
import Instructions from './components/Instructions';
import Languages from './components/Languages';
import Changelog from './components/Changelog';
import Roadmap from './components/Roadmap';

import 'tailwindcss/tailwind.css';

function App() {
  const [showInstructions, setShowInstructions] = useState(false);
  const [showChangelog, setShowChangelog] = useState(false);
  const [showRoadmap, setShowRoadmap] = useState(false);
  const [currentLogo, setCurrentLogo] = useState('');
  const [currentBackground, setCurrentBackground] = useState('');
  const location = useLocation();
  const isMainIndex = location.pathname === "/";
  const isGamePage = location.pathname === "/game";
  const isLeaderboardPage = location.pathname === "/leaderboard";
  const isMultiplayerPage = location.pathname === "/multiplayer";

  // List of logos
  const logos = [
    '/images/logo3.png',
    '/images/logo4.png',
    '/images/logo5.png',
    '/images/logo6.png',
  ];

  // List of background images
  const backgrounds = [
    '/images/bg.png',
    '/images/bg2.png',
    '/images/bg3.png',
    '/images/bg4.png',
  ];

  // Function to determine the logo and background by hour (CET timezone)
  useEffect(() => {
    const determineAssetsByHour = () => {
      const currentCETHour = new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Paris',
        hour: '2-digit',
        hour12: false,
      });

      const index = parseInt(currentCETHour, 10) % logos.length;
      setCurrentLogo(logos[index]);
      setCurrentBackground(backgrounds[index]);
    };

    determineAssetsByHour();
    const intervalId = setInterval(determineAssetsByHour, 3600000);

    return () => clearInterval(intervalId);
  }, [logos, backgrounds]);

  return (
    <div
      className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4"
      style={{
        backgroundImage: `url(${currentBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={`w-full ${isMainIndex ? "max-w-md" : "max-w-full"} p-4`}>
        {/* Homepage Card */}
        {isMainIndex && (
          <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
            <div className="flex justify-center mb-6">
              <img src={currentLogo} alt="Logo" className="w-32 h-32" />
            </div>
            <h1 className="text-4xl font-bold text-center mb-2">LangGuesser</h1>
            <p className="text-center mb-6">
              Guess the language's country by its accent
            </p>
            <NicknameInput />
          </div>
        )}

        {/* Game, Multiplayer and Leaderboard pages */}
        {!isMainIndex && (
          <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
            <Routes>
              <Route path="/game" element={<Game />} />
              <Route path="/multiplayer" element={<Multiplayer />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/languages" element={<Languages />} />
            </Routes>
          </div>
        )}
      </div>

      {/* Footer Links */}
      {isMainIndex && (
        <div className="text-center mt-6">
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 text-blue-200">
            <button
              className="focus:outline-none hover:text-blue-400"
              onClick={() => setShowInstructions(true)}
            >
              How to Play
            </button>
            <span className="hidden sm:inline text-white">|</span>
            <button
              className="focus:outline-none hover:text-blue-400"
              onClick={() => setShowChangelog(true)}
            >
              Changelog
            </button>
            <span className="hidden sm:inline text-white">|</span>
            <button
              className="focus:outline-none hover:text-blue-400"
              onClick={() => setShowRoadmap(true)}
            >
              Roadmap
            </button>
          </div>
          <Instructions isOpen={showInstructions} onClose={() => setShowInstructions(false)} />
          <Changelog isOpen={showChangelog} onClose={() => setShowChangelog(false)} />
          <Roadmap isOpen={showRoadmap} onClose={() => setShowRoadmap(false)} />

          {/* "Buy Me a Coffee" Link */}
          <div className="mt-5">
            <a
              href="https://buymeacoffee.com/langguesser"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-300 hover:text-yellow-500"
            >
              Buy me a coffee ☕
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
